const list = [
	"Albania",
	"Latvia",
	"Andorra",
	"Liechtenstein",
	"Armenia",
	"Lithuania",
	"Austria",
	"Luxembourg",
	"Malta",
	"Belarus",
	"Moldova",
	"Belgium",
	"Monaco",
	"Bosnia and Herzegovina",
	"Montenegro",
	"Bulgaria",
	"Netherlands",
	"Croatia",
	"Norway",
	"Cyprus",
	"Poland",
	"Czechia",
	"Portugal",
	"Denmark",
	"Romania",
	"Estonia",
	"Russia",
	"Finland",
	"San Marino",
	"Macedonia",
	"Serbia",
	"France",
	"Slovakia",
	"Georgia",
	"Slovenia",
	"Germany",
	"Spain",
	"Greece",
	"Hungary",
	"Sweden",
	"Iceland",
	"Switzerland",
	"Ireland",
	"Turkey",
	"Italy",
	"Ukraine",
	"Kosovo",
	"United Kingdom",
];

const europeanCountries: TGameSetupRecord = {
	id: "europeanCountries",
	answers: list,
	entityName: "European Country",
	title: "Europable",
	suggestions: "to-answers",
	validWords: "answers",
};

export default europeanCountries;
